body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(206, 109, 40);
    background: -moz-linear-gradient(135deg, rgba(206, 109, 40, 1) 65%, rgba(241, 181, 44, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(206, 109, 40, 1) 65%, rgba(241, 181, 44, 1) 100%);
    background: linear-gradient(135deg, rgba(206, 109, 40, 1) 65%, rgba(241, 181, 44, 1) 100%);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}